import * as React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Layout from "../components/layout";

const BlogPost = styled.div`
  display: flex;

  > * + * {
      margin-left: 1rem;
  }
`;

const BlogDate = styled.div``;

const StyledLink = styled(Link)`
    font-weight: bold;
    text-decoration: none;
`;

const IndexPage = ({ data }) => {
  const bodyText = data.text.body.childMarkdownRemark.html;
  const headerPicture = data.text.headerPicture;

  return (
    <Layout headerPicture={headerPicture}>
      <Helmet>
        <title>{data.text.title}</title>
      </Helmet>
      <div
        className="body"
        dangerouslySetInnerHTML={{
          __html: bodyText,
        }}
      />
      {data.blogs.nodes.map((blog) => (
        <BlogPost>
          <StyledLink to={blog.slug}>{blog.title}</StyledLink>
          <BlogDate>{new Date(blog.date).toLocaleDateString("fi-FI")}</BlogDate>
        </BlogPost>
      ))}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    text: contentfulPage(path: { eq: "kuulumisia" }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      headerPicture {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
    }
    blogs: allContentfulBlog(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        title
        date
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
